import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

const sizing = {
  maxHeight: '353px',
  maxWidth: '473px',
};

const wrap = {
  ...sizing,
  marginBottom: '96px',
};

const image = {
  ...sizing,
};

/**
 * Contact page Hero component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Hero />
 */

const Hero = () => {
  const { contentfulAsset } = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "contact-hero" }) {
        fluid(
          maxHeight: 448
          maxWidth: 600
          quality: 85
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `);

  return (
    <div
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyBetween,
        base.w100,
        main.contactImgWrap
      )}
    >
      <Img
        fluid={contentfulAsset.fluid}
        alt=""
        aria-hidden="true"
        loading="eager"
        style={wrap}
        imgStyle={image}
      />
      <h1 className={cx(main.contactImgCopy, main.heading, theme.colorDark)}>
        Engage <span className={theme.colorBlue}>intelligently</span>.
      </h1>
    </div>
  );
};

export default Hero;
