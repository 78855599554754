import React from 'react';
import cx from 'classnames';
import HubspotContactForm from './hubspotContactForm';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

/**
 * contact page Form component.
 * @component
 *
 * @returns {component} - <Form />
 */

const Form = () => {
  return (
    <>
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyCenter,
          base.w100,
          main.contactFormWrap
        )}
      >
        <HubspotContactForm
          region="na1"
          portalId="2098667"
          formId="0aed86fb-857d-4494-9a4b-483f6f0e5f2a"
        />
      </div>
    </>
  );
};

export default Form;
