import React from 'react';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Form from './contactForm';
import Hero from './contactHero';

/**
 * Contact page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = () => (
  <section
    className={cx(
      base.flex,
      base.itemsCenter,
      base.justifyCenter,
      base.w100,
      main.contactMain
    )}
  >
    <div
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyBetween,
        base.w100,
        main.contactInner
      )}
    >
      <Hero />
      <div className={cx(base.h100, main.contactDivider)} />
      <Form />
    </div>
  </section>
);

export default Main;
