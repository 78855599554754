import React from 'react';
import PropTypes from 'prop-types';
import { initialize } from 'react-ga';

import Layout from '../components/layout';
import Main from '../components/contact/contactMainContent';

const pageDescription =
  'We love to talk shop. Contact us to learn more about our member engagement suite.';

/**
 * Contact page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Contact location={string} />
 */

const Contact = ({ location }) => {
  initialize('UA-33936956-01', {
    debug: true,
    titleCase: false,
  });

  return (
    <Layout
      pageTitle="Contact Us"
      pageDescription={pageDescription}
      location={location}
    >
      <Main location={location} />
    </Layout>
  );
};

Contact.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Contact;
